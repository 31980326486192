<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link
          class="text-decoration--none"
          :to="{ name: 'questionnaires' }"
          >Questionnaires</router-link
        >
      </v-toolbar-title>

      <div class="flex-grow-1"></div>

      <v-btn @click="deleteDialog = true" color="error" text dark>
        <v-icon class="mr-3">
          {{ icons.delete }}
        </v-icon>
        Delete Questionnaire
      </v-btn>
    </v-app-bar>

    <v-row>
      <v-col cols="12">
        <div class="ma-4" v-if="form">
          <div class="display-1 font-weight-bold my-6 text-capitalize">
            {{ displayName }}
          </div>

          <div class="my-6">
            <v-form
              ref="form"
              v-if="form"
              @submit.prevent="updateQuestionnaireDetails"
            >
              <v-divider></v-divider>
              <div class="d-flex justify-space-between align-center mb-8">
                <h3 class="py-5 mr-auto primary--text">
                  Questionnaire Details
                </h3>
                <v-btn
                  type="submit"
                  color="primary"
                  width="150px"
                  :loading="form.$busy"
                  depressed
                >
                  Update
                </v-btn>
              </div>

              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    required
                    hide-details="auto"
                    label="Name *"
                    v-model="form.name"
                    :error-messages="form.$getError('name')"
                    :loading="loading"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    outlined
                    required
                    hide-details="auto"
                    label="Questionnaire Category *"
                    v-model="form.question_category_id"
                    :items="questionnaireCategories"
                    :error-messages="form.$getError('question_category_id')"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    outlined
                    required
                    hide-details="auto"
                    label="Question Type *"
                    v-model="form.question_type"
                    :items="questionTypes"
                    :error-messages="form.$getError('question_type')"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    required
                    hide-details="auto"
                    label="Label"
                    v-model="form.label"
                    :error-messages="form.$getError('label')"
                    :loading="loading"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    required
                    hide-details="auto"
                    label="Note"
                    v-model="form.note"
                    :error-messages="form.$getError('note')"
                    :loading="loading"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete
                    outlined
                    v-model="form.exercise_id"
                    :items="exercises"
                    :loading="isLoadingExercise"
                    :search-input.sync="exerciseSearch"
                    hide-no-data
                    hide-selected
                    clearable
                    item-text="name"
                    item-value="id"
                    label="Related Exercise"
                    placeholder="Start typing to search"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <label class="text-field-label">Choices</label>
                  <v-btn
                    x-small
                    fab
                    icon
                    v-if="questionnaireChoices.length <= 4"
                    @click="addChoiceDialog = true"
                  >
                    <v-icon color="green lighten-2">{{ icons.plus }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-card
                class="my-7"
                v-for="(choice, index) in questionnaireChoices"
                :key="index"
              >
                <input
                  type="file"
                  :ref="`choiceImageInput${choice.id}`"
                  @change="choiceImageChange($event, choice)"
                  style="display: none"
                  accept="image/*"
                />
                <v-row class="pa-5" align="center">
                  <v-col cols="4">
                    <template>
                      <v-skeleton-loader
                        tile
                        width="160"
                        height="160"
                        type="card-avatar"
                        v-if="choice.isImageLoading"
                      ></v-skeleton-loader>
                      <v-avatar v-else size="160" tile>
                        <img :src="thumbnailSrc(choice.popup_image)" />
                      </v-avatar>
                    </template>
                  </v-col>
                  <v-col cols="8">
                    <v-row>
                      <v-col md="12" lg="6">
                        <v-btn
                          text
                          class="text-capitalize font-weight-bold text-light-blue"
                          @click="choiceImageInput(choice)"
                          :loading="choice.isImageLoading"
                        >
                          <img
                            src="@/assets/icons/icon-image.svg"
                            class="mr-2"
                            alt
                          />Upload Image
                        </v-btn>
                      </v-col>
                      <v-col md="12" lg="6">
                        <v-btn
                          text
                          class="text-capitalize font-weight-bold text-light-grey"
                          @click="deleteChoiceImage(choice)"
                          v-if="choice.popup_image"
                        >
                          <img
                            src="@/assets/icons/icon-trash.svg"
                            class="mr-2"
                            alt
                          />Delete Image
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      outlined
                      required
                      hide-details="auto"
                      label="Value *"
                      v-model="choice.value"
                      :error-messages="form.$getError(`choices.${index}.value`)"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="7">
                    <v-text-field
                      outlined
                      required
                      hide-details="auto"
                      label="Description"
                      v-model="choice.description"
                      :error-messages="
                        form.$getError(`choices.${index}.description`)
                      "
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      outlined
                      required
                      hide-details="auto"
                      label="Popup Title"
                      v-model="choice.popup.title"
                      :error-messages="
                        form.$getError(`choices.${index}.popup.title`)
                      "
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="7">
                    <v-text-field
                      outlined
                      required
                      hide-details="auto"
                      label="Popup Message"
                      v-model="choice.popup.message"
                      :error-messages="
                        form.$getError(`choices.${index}.popup.message`)
                      "
                    >
                    </v-text-field>
                  </v-col>

                  <v-btn-toggle rounded class="floatingBtn">
                    <v-btn x-small fab icon @click="choiceUpdate(choice)">
                      <v-icon color="blue">{{ icons.save }}</v-icon>
                    </v-btn>
                    <v-btn
                      x-small
                      fab
                      icon
                      @click="
                        deleteChoiceConfirmDialog = true
                        deleteQuestionnaireChoiceId = choice.id
                      "
                    >
                      <v-icon color="red">{{ icons.delete }}</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </v-row>
              </v-card>

              <v-row>
                <v-col cols="12">
                  <label class="text-field-label">Has Popup?</label>
                </v-col>
                <v-col>
                  <v-row align="center" class="mx-1">
                    <v-checkbox
                      v-model="form.is_popup_next"
                      hide-details
                      class="shrink mr-2 mt-0"
                      :error-messages="form.$getError('is_popup_next')"
                    ></v-checkbox>
                    <v-text-field
                      outlined
                      required
                      hide-details="auto"
                      label="Popup Message"
                      v-model="form.popup_message"
                      :disabled="!form.is_popup_next"
                      :error-messages="form.$getError('popup_message')"
                    ></v-text-field>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="form.is_user_medication_related"
                    label="Is List Related to User's Medication?"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <label class="text-field-label">Has Subtext?</label>
                </v-col>
                <v-col>
                  <v-row align="center" class="mx-1">
                    <v-col cols="1">
                      <v-checkbox
                        v-model="subtextFields"
                        hide-details
                        class="shrink mr-2 mt-0"
                        :error-messages="form.$getError('is_popup_next')"
                      ></v-checkbox>
                    </v-col>
                    <v-col>
                      <v-row>
                        <v-combobox
                          solo
                          flat
                          required
                          v-model="form.subtext.list"
                          :items="subtextListItems"
                          :search-input.sync="searchSubtextList"
                          hide-selected
                          hint="Maximum of 5 tags"
                          label="Subtext List"
                          multiple
                          persistent-hint
                          small-chips
                          :disabled="!subtextFields"
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  No results matching "<strong>{{
                                    search
                                  }}</strong
                                  >". Press <kbd>enter</kbd> or
                                  <kbd>tab</kbd> to create a new one
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-combobox>
                      </v-row>
                      <v-row>
                        <v-text-field
                          solo
                          flat
                          required
                          label="Subtext Message"
                          hide-details="auto"
                          v-model="form.subtext.message"
                          :error-messages="form.$getError('subtext.message')"
                          :disabled="!subtextFields"
                        ></v-text-field>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="mt-6">
                <v-col cols="12">
                  <label class="text-field-label">Contact Dialog Details</label>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    hide-details="auto"
                    label="Description"
                    v-model="form.contact.description"
                    :error-messages="form.$getError('contact.description')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    hide-details="auto"
                    label="Contact Number"
                    v-model="form.contact.number"
                    :error-messages="form.$getError('contact.number')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-6">
                <v-col cols="12">
                  <v-checkbox
                    v-model="form.is_custom_screen_next"
                    hide-details
                    class="shrink mr-2 mt-0"
                    :error-messages="form.$getError('is_custom_screen_next')"
                  >
                    <template v-slot:label>
                      <div class="text-field-label">
                        Custom Screen Next?
                      </div>
                    </template></v-checkbox
                  >
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col sm="6" md="4">
                  <template>
                    <v-skeleton-loader
                      tile
                      width="160"
                      height="160"
                      type="card-avatar"
                      v-if="imageLoading"
                    ></v-skeleton-loader>
                    <v-avatar v-else size="160" tile>
                      <img
                        :src="
                          thumbnailSrc(questionnaire.custom_screen_next_image)
                        "
                      />
                    </v-avatar>
                  </template>
                </v-col>
                <v-col sm="6" md="8">
                  <v-file-input
                    show-size
                    v-model="customScreenNextImageFile"
                    label="Custom Screen Image"
                    outlined
                    :disabled="!form.is_custom_screen_next || imageLoading"
                    :error-messages="form.$getError('image')"
                    :loading="imageLoading"
                  ></v-file-input>
                  <v-row>
                    <v-col md="12" lg="6">
                      <v-btn
                        text
                        class="text-capitalize font-weight-bold text-light-blue"
                        :loading="imageLoading"
                        :disabled="
                          customScreenNextImageFile == null &&
                            !form.is_custom_screen_next
                        "
                        @click="imageChange"
                      >
                        <img
                          src="@/assets/icons/icon-image.svg"
                          class="mr-2"
                          alt
                        />Upload Image
                      </v-btn>
                    </v-col>
                    <v-col md="12" lg="6">
                      <v-btn
                        v-if="questionnaire.custom_screen_next_image"
                        text
                        class="text-capitalize font-weight-bold text-light-grey"
                        @click="deleteImageConfirmDialog = true"
                        :loading="imageLoading"
                      >
                        <img
                          src="@/assets/icons/icon-trash.svg"
                          class="mr-2"
                          alt
                        />
                        Delete Image
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    hide-details="auto"
                    label="Custom Screen Description"
                    v-model="form.custom_screen_next.description"
                    :disabled="!form.is_custom_screen_next"
                    :error-messages="
                      form.$getError('custom_screen_next.description')
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    hide-details="auto"
                    label="Custom Screen Button Label"
                    v-model="form.custom_screen_next.button_label"
                    :disabled="!form.is_custom_screen_next"
                    :error-messages="
                      form.$getError('custom_screen_next.button_label')
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>

            <v-dialog v-model="deleteDialog" max-width="290">
              <v-card>
                <v-card-title class="headline">Delete ?</v-card-title>

                <v-card-text
                  >Are you sure you want to delete this question?</v-card-text
                >

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn text @click="deleteDialog = false">Cancel</v-btn>

                  <v-btn
                    color="red"
                    text
                    @click="questionnaireDelete"
                    :loading="isLoadingDelete"
                    >Yes, Delete please</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-overlay absolute :value="loading" opacity="0.2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>

    <ConfirmModal
      v-model="deleteImageConfirmDialog"
      title="Remove Image"
      message="Are you sure you want to remove the image?"
      @cancel="deleteImageConfirmDialog = false"
      @confirm="imageRemoved"
    ></ConfirmModal>

    <ConfirmModal
      v-model="deleteChoiceConfirmDialog"
      title="Delete Choice"
      message="Are you sure you want to delete the choice?"
      @cancel="deleteChoiceConfirmDialog = false"
      @confirm="removeChoiceForm"
    ></ConfirmModal>

    <AddChoiceDialog
      v-if="form"
      v-model="addChoiceDialog"
      :questionnaire="questionnaire"
    >
    </AddChoiceDialog>
  </div>
</template>

<script>
import {
  mdiChevronLeft,
  mdiCheck,
  mdiAlertCircleOutline,
  mdiTrashCanOutline,
  mdiPlus,
  mdiContentSaveOutline
} from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import Form from '@/utils/form'
import ConfirmModal from '@/components/modals/ConfirmModal'
import AddChoiceDialog from './components/AddChoiceDialog.vue'

export default {
  name: 'QuestionnaireDetails',

  components: {
    AppBarNavIcon,
    ConfirmModal,
    AddChoiceDialog
  },

  data() {
    return {
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
        delete: mdiTrashCanOutline,
        plus: mdiPlus,
        save: mdiContentSaveOutline
      },
      loading: false,
      avatarLoading: false,
      deleteDialog: false,
      form: null,
      showCropperModal: false,
      avatarTimestamp: new Date().getTime(),
      tabItems: [{ tab: 'Info' }],
      items: ['Yes', 'No'],
      search: null,
      searchSubtextList: null,
      questionTypes: [
        { text: 'Multiple Choice', value: 'multiple_choice' },
        { text: 'True or False', value: 'true_or_false' },
        { text: 'Open Ended', value: 'open_ended' },
        { text: 'Identification', value: 'identification' }
      ],
      subtextListItems: [],
      snackbar: {
        show: false,
        message: null,
        color: ''
      },
      showModal: false,
      isLoadingDelete: false,
      options: {
        itemsPerPage: 100,
        sortBy: []
      },
      subtextFields: false,
      rules: {
        required: [value => !!value || 'Required']
      },
      exerciseModel: null,
      isLoadingExercise: false,
      exerciseSearch: null,
      exerciseOptions: {
        itemsPerPage: 1000,
        sortBy: []
      },
      imageLoading: false,
      deleteImageConfirmDialog: false,
      customScreenNextImageFile: null,
      addChoiceDialog: false,
      deleteChoiceConfirmDialog: false,
      deleteQuestionnaireChoiceId: null,
      loadingTest: false
    }
  },

  computed: {
    ...mapState('questionnaires', {
      questionnaire: state => ({
        ...state.questionnaireDetails,
        contact: state.questionnaireDetails
          ? {
              description:
                state.questionnaireDetails.contact &&
                state.questionnaireDetails.contact.description,
              number:
                state.questionnaireDetails.contact &&
                state.questionnaireDetails.contact.number
            }
          : {},
        custom_screen_next: state.questionnaireDetails
          ? {
              description:
                state.questionnaireDetails.custom_screen_next &&
                state.questionnaireDetails.custom_screen_next.description,
              button_label:
                state.questionnaireDetails.custom_screen_next &&
                state.questionnaireDetails.custom_screen_next.button_label
            }
          : {},
        subtext: state.questionnaireDetails
          ? {
              list:
                state.questionnaireDetails.subtext &&
                state.questionnaireDetails.subtext.list,
              message:
                state.questionnaireDetails.subtext &&
                state.questionnaireDetails.subtext.message
            }
          : {}
      }),
      questionnaireChoices: state => state.questionnaireDetails.choices
    }),
    ...mapState({
      authUser: state => state.auth.user,
      questionnaireCategories: state =>
        state.questionnaireCategory.list.map(list => ({
          text: list.name,
          value: list.id
        })),
      exercises: state =>
        state.exercise.list.map(list => ({
          id: list.id,
          name: list.name
        }))
    }),
    displayName() {
      return this.form.name || 'hey'
    }
  },

  methods: {
    ...mapActions({
      getQuestionnaireDetails: 'questionnaires/getQuestionnaireDetails',
      updateQuestionnaire: 'questionnaires/updateQuestionnaire',
      getQuestionnaireCategories: 'questionnaireCategory/getCategories',
      getExerciseList: 'exercise/getExercises',
      changeImage: 'questionnaires/updateCustomScreenNextImage',
      removeImage: 'questionnaires/removeCustomScreenNextImage',
      deleteQuestionnaire: 'questionnaires/deleteQuestionnaire',
      deleteQuestionnaireChoice: 'questionnaires/deleteChoice',
      updateChoice: 'questionnaires/updateChoice',
      changeChoiceImage: 'questionnaires/updateChoicePopupImage',
      removeChoiceImage: 'questionnaires/deleteChoicePopupImage'
    }),

    ...mapMutations({
      clearQuestionnaireDetails: 'questionnaires/clearQuestionnaireDetails',
      clearQuestionnaireCategories: 'questionnaireCategory/clearCategoryList',
      clearExerciseList: 'exercise/clearExerciseList'
    }),

    async getQuestionnaire() {
      await this.getQuestionnaireDetails(this.$route.params.id)
      this.form = new Form({
        ...this.questionnaire,
        question_category_id: this.questionnaire.questionnaire_category[0].id,
        subtext: {
          list: this.questionnaire.subtext && this.questionnaire.subtext.list,
          message:
            this.questionnaire.subtext && this.questionnaire.subtext.message
        }
      })
      this.$nextTick()
      this.subtextFields =
        this.form.subtext &&
        !!(this.form.subtext.list || this.form.subtext.message)
    },

    async getCategories() {
      this.clearQuestionnaireCategories()
      await this.getQuestionnaireCategories({
        search: null,
        ...this.options
      })
    },

    async updateQuestionnaireDetails() {
      this.form.$busy = true
      this.form.$clearErrors()
      const formData = JSON.parse(JSON.stringify(this.form.$data()))
      if (!this.subtextFields) {
        this.form.subtext = {
          list: [],
          message: null
        }
        formData.subtext = null
      }
      if (!formData.contact.description && !formData.contact.number) {
        formData.contact = null
      }
      this.updateQuestionnaire(formData)
        .then(() => {
          this.showSnackbar('Questionnaire successfully updated!', 'success')
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    async questionnaireDelete() {
      this.isLoadingDelete = true
      await this.deleteQuestionnaire(this.questionnaire.id)
      this.isLoadingDelete = false
      this.deleteDialog = false
      this.showSnackbar('Questionnaire deleted successfully!')
      this.$router.replace('/questionnaires/onboarding')
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    async removeChoiceForm() {
      this.deleteChoiceConfirmDialog = false
      this.loading = true
      const data = {
        questionnaire_id: this.questionnaire.id,
        id: this.deleteQuestionnaireChoiceId
      }
      await this.deleteQuestionnaireChoice(data)
      await this.getQuestionnaireDetails(this.questionnaire.id)

      this.showSnackbar('Choice has been deleted', 'success')
      this.loading = false
    },

    async getExercises() {
      this.isLoadingExercise = true
      this.clearExerciseList()
      await this.getExerciseList({
        search: null,
        ...this.exerciseOptions
      })
      this.isLoadingExercise = false
    },

    thumbnailSrc(image) {
      return image ? image.url : require('@/assets/images/default-image.jpeg')
    },

    async choiceImageInput(choice) {
      this.$refs[`choiceImageInput${choice.id}`][0].value = null
      this.$refs[`choiceImageInput${choice.id}`][0].click()
    },

    async choiceImageChange(event, choice) {
      // this.loading = true

      const data = new FormData()
      const file = event.target.files[0]

      data.append('file', file)
      data.append('questionnaire_id', this.questionnaire.id)
      data.append('id', choice.id)
      this.changeChoiceImage(data)
        .then(() => {
          this.showSnackbar(
            'Popup Image has been uploaded to choice.',
            'success'
          )
        })
        .catch(() => {
          this.showSnackbar(
            'There is something wrong with the attachment',
            'danger'
          )
        })
        .finally(() => {
          // this.loading = false
        })
    },

    async imageChange() {
      this.imageLoading = true
      let data = new FormData()
      data.append('image', this.customScreenNextImageFile)
      data.append('id', this.questionnaire.id)
      this.changeImage(data)
        .then(res => {
          this.showSnackbar(
            'Questionnaire Custom Screen image successfully updated!',
            'success'
          )
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => {
          this.imageLoading = false
        })
    },

    async imageRemoved() {
      this.deleteImageConfirmDialog = false
      this.imageLoading = true
      this.removeImage(this.questionnaire.id)
        .then(() => {
          this.showSnackbar(
            'Questionnaire Custom Screen image successfully deleted!',
            'success'
          )
        })
        .finally(() => {
          this.imageLoading = false
        })
    },

    async choiceUpdate(choice) {
      this.loading = true

      this.updateChoice({
        ...choice,
        popup:
          !choice.popup.title && !choice.popup.message ? null : choice.popup,
        questionnaire_id: this.questionnaire.id
      })
        .then(() => {
          this.showSnackbar('Choice has been updated', 'success')
        })
        .finally(() => (this.loading = false))
    },

    async deleteChoiceImage(choice) {
      this.removeChoiceImage({
        questionnaire_id: this.questionnaire.id,
        id: choice.id
      }).then(() => {
        this.showSnackbar('Choice Popup Image has been removed', 'success')
      })
    }
  },

  async created() {
    this.loading = true
    await this.getCategories()
    await this.getExercises()
    await this.getQuestionnaire()
    this.loading = false
  },

  destroyed() {
    this.clearQuestionnaireDetails()
  },

  watch: {
    $route() {
      this.getQuestionnaire()
    },
    exerciseSearch(val) {
      if (this.exercises.length > 0) return

      // Items have already been requested
      if (this.isLoadingExercise) return

      this.getExercises()
    },
    questionnaire() {
      this.form = new Form({
        ...this.questionnaire,
        question_category_id: this.questionnaire.questionnaire_category[0].id
      })
    }
  }
}
</script>

<style scoped>
.floatingBtn {
  position: absolute;
  top: -10px;
  right: -33px;
}
</style>
